<template>
  <div>
    <iframe :src="publicPath + 'animate/pcdd/index.html'" scrolling="no" class="iframe" frameborder="0" ref="iframe" :height="iframeHeight"></iframe>
  </div>
</template>

<script>

import moment from 'moment'

// 开奖延时 (秒)
// const kOpenDelay = 60
// const kOpenDelay = 10
// 倒计时延迟
const kCountdownDelay = 3
// 动画播放时间
const kAnimateSec = 3
// 开奖结果显示时间
// const kResultPageDelay = 15
// 请求间隔
const kRequestInterval = 2

export default {
  name: 'pcdd',
  props: {
    code: String,
    id: String
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      iframeHeight: 870,
      midGame: false,
      timer: null,
      currentOpenCode: null
    }
  },
  mounted () {
    this.$refs.iframe.onload = () => {
      this.iframeSizeFit()
      this.getState()
      // this.test()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    iframeSizeFit () {
      this.getIframeContentWindow(window => {
        const originWidth = 1334
        const originHeight = 870
        const currentWidth = this.$refs.iframe.offsetWidth
        const zoom = currentWidth / originWidth

        const html = window.document.documentElement
        html.style.zoom = zoom

        this.iframeHeight = originHeight * zoom

        window.ifopen = true
      })
    },
    test () {
      this.getIframeContentWindow(window => {
        window.pcEgg.startRoate()
      })
    },
    getState () {
      this.$api.live(this.code, this.id).then(({ data }) => {
        if (this.currentOpenCode !== data.opencode) {
          this.setupData(data)
        } else {
          var timer = setTimeout(() => {
            this.getState()
            clearTimeout(timer)
          }, kRequestInterval * 1000)
        }
      }).catch(err => {
        this.$message.error(err)
        var timer = setTimeout(() => {
          this.getState()
          clearTimeout(timer)
        }, 3000)
      })
    },
    setupData (data) {
      function formatTime (value) {
        return (Array(2).join(0) + value).slice(-2)
      }

      this.currentOpenCode = data.opencode

      const currentTime = new Date().getTime()
      // const nextTime = (data.next_open_time + kOpenDelay) * 1000
      const nextTime = data.next_open_time * 1000

      var time = nextTime - currentTime
      if (time <= 0) {
        time = kCountdownDelay * 1000
      }
      const h = formatTime(parseInt(time / 1000 / 60 / 60))
      const m = formatTime(parseInt(time / 1000 / 60 % 60))
      const s = formatTime(parseInt(time / 1000 % 60))

      const drawTime = moment(nextTime).format('HH:mm')
      const nextIssue = data.next_expect
      const numArr = data.opencode_arr
      const preDrawTime = [h, m, s].join(':')

      this.getIframeContentWindow(window => {
        window.pcEgg.stopRoate()
        window.pcEgg.startVid({ drawTime, nextIssue, numArr, preDrawTime })
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.getState()
        }, time + kAnimateSec * 1000)
      })
    },
    getIframeContentWindow (handler) {
      handler(this.$refs.iframe.contentWindow)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
}
</style>
